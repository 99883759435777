
exports.BACKEND_URL = process.env.REACT_APP_API_URL;

// Global variable for decimal places
const stepValue = 3;
exports.decimalplaces = 1 / 10 ** stepValue;

exports.GeneralStatus = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
};

exports.TransactionTypes = [
  {
    id: "Deposit",
    type: "Deposit",
  },
  {
    id: "Withdrawl",
    type: "Withdrawl",
  },
];

exports.mainAccountsWithPaidThrough = [
  "Other Income",
  "Capital",
  "Expense",
  "Fix Assets",
  "Current Assets",
  "Current Liability",
];

exports.incomeMainAccounts = [
  "Other Income",
  "Capital",
  "Current Liability",
  "Long Term Liability",
];
exports.expenseMainAccounts = [
  "Expense",
  "Current Assets",
  "Fix Assets",
  "Cost of Good Sold",
];
